import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import contactSagas from "@iso/redux/contacts/saga";
import invoicesSagas from "@iso/redux/invoice/saga";
import mailSagas from "@iso/redux/mail/saga";
import notesSagas from "@iso/redux/notes/saga";
import todosSagas from "@iso/redux/todos/saga";
import ecommerceSaga from "@iso/redux/ecommerce/saga";
import ecommerceSagaOutside from "@iso/redux/ecommerceOutside/saga";
import cardsSagas from "@iso/redux/card/saga";

import youtubeSearchSagas from "@iso/redux/youtubeSearch/sagas";
import githubSagas from "@iso/redux/githubSearch/sagas";

import scrumBoardSaga from "@iso/redux/scrumBoard/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    ecommerceSagaOutside(),
    youtubeSearchSagas(),
    githubSagas(),

    scrumBoardSaga(),
  ]);
}
