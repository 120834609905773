import actions from "./actions";

const initState = {
  condition: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.CHANGE_LOADER:
      return {
        ...state,
        condition: action.condition,
      };
    default:
      return state;
  }
};
