const actions = {
  CHANGE_LOADER: "CHANGE_LOADER",

  changeLoader: (condition) => {
    return {
      type: actions.CHANGE_LOADER,
      condition,
    };
  },
};
export default actions;
