import React from "react";
import Image from "@iso/assets/images/rob.png";
import FourZeroFourStyleWrapper from "./404.styles";

export default function () {
  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Content">
        <h2>System Upgrading</h2>
        <h3>Please come back later</h3>
        <p>The page you're looking for doesnt exist or has been moved.</p>
      </div>

      <div className="iso404Artwork">
        <img alt="#" src={Image} />
      </div>
    </FourZeroFourStyleWrapper>
  );
}
