const actions = {
  INIT_DATA: "ECOMMERCE_INIT_DATA",
  INIT_DATA_SAGA: "ECOMMERCE_INIT_DATA_SAGA",
  UPDATE_DATA: "ECOMMERCE_UPDATE_DATA",
  UPDATE_DATA_TEMP: "ECOMMERCE_UPDATE_DATA_TEMP",
  UPDATE_DATA_SAGA: "ECOMMERCE_UPDATE_DATA_SAGA",
  UPDATE_DATA_TEMP_SAGA: "ECOMMERCE_UPDATE_DATA_TEMP_SAGA",
  CHANGE_VIEW: "ECOMMERCE_CHANGE_VIEW",
  VIEW_TOPBAR_CART: "ECOMMERCE_VIEW_TOPBAR_CART",
  CLEAR_CART: "ECOMMERCE_CLEAR_CART",
  CLEAR_CART_SAGA: "ECOMMERCE_CLEAR_CART_SAGA",
  CLEAR_CART_TEMP: "ECOMMERCE_CLEAR_CART_TEMP",
  CHANGE_MALL_TYPE: "ECOMMERCE_CHANGE_MALL_TYPE",
  CHANGE_MALL_TYPE_SAGA: "ECOMMERCE_CHANGE_MALL_TYPE_SAGA",
  initData: () => ({ type: actions.INIT_DATA_SAGA }),
  changeView: (view) => ({
    type: actions.CHANGE_VIEW,
    view,
  }),
  changeViewTopbarCart: (viewTopbarCart) => {
    return {
      type: actions.VIEW_TOPBAR_CART,
      viewTopbarCart,
    };
  },
  changeProductQuantity: (productQuantity) => {
    return (dispatch, getState) => {
      const { products } = getState().Ecommerce;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity,
      });
    };
  },
  changeProductQuantityTemp: (tempProductQuantity) => {
    return (dispatch, getState) => {
      const { tempProducts } = getState().Ecommerce;
      dispatch({
        type: actions.UPDATE_DATA_TEMP_SAGA,
        tempProducts,
        tempProductQuantity,
      });
    };
  },
  addToCart2: (product, quantity) => {
    return (dispatch, getState) => {
      const { products, productQuantity } = getState().Ecommerce;
      const productCode = product.productCode;

      productQuantity.push({ productCode, quantity: quantity });
      products[productCode] = product;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity,
      });
    };
  },
  addToCartTemp: (product, quantity) => {
    return (dispatch, getState) => {
      const { tempProducts, tempProductQuantity } = getState().Ecommerce;
      const productCode = product.productCode;

      tempProductQuantity.push({ productCode, quantity: quantity });
      tempProducts[productCode] = product;
      dispatch({
        type: actions.UPDATE_DATA_TEMP_SAGA,
        tempProducts,
        tempProductQuantity,
      });
    };
  },
  clearCart: () => ({
    type: actions.CLEAR_CART_SAGA,
  }),
  clearCartTemp: () => {
    return {
      type: actions.CLEAR_CART_TEMP,
    };
  },
  changeMallType: (mallType) => {
    return {
      type: actions.CHANGE_MALL_TYPE_SAGA,
      mallType,
    };
  },
};
export default actions;
