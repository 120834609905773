import { all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import fake from "./fake";
import fakeinitdata from "./config";

export function* changedCard() {
  yield takeEvery(actions.CHANGE_CARDS, function* () {});
}
export function* initData() {
  let fakeData = fakeinitdata;
  //alert(JSON.stringify(fakeinitdata));
  if (fakeinitdata.productQuantity.length === 0) {
    fakeData = fake;
  }
  yield put({
    type: actions.INIT_DATA,
    payload: fakeData,
  });
}
export function* updateData({ products, productQuantity }) {
  localStorage.setItem("cartProductQuantityOutside", JSON.stringify(productQuantity));
  localStorage.setItem("cartProductsOutside", JSON.stringify(products));
  yield put({
    type: actions.UPDATE_DATA,
    products,
    productQuantity,
  });
}
export function* updateDataTemp({ tempProducts, tempProductQuantity }) {
  yield put({
    type: actions.UPDATE_DATA_TEMP,
    tempProducts,
    tempProductQuantity,
  });
}

export function* clearCart() {
  localStorage.removeItem("cartProductQuantityOutside");
  localStorage.removeItem("cartProductsOutside");
  localStorage.removeItem("cartProductQuantity");
  localStorage.removeItem("cartProducts");
  const products = [];
  const productQuantity = [];
  yield put({
    type: actions.CLEAR_CART,
    products,
    productQuantity,
  });
}

export function* changeMallType({ mallType }) {
  localStorage.setItem("mallType", JSON.stringify(mallType));

  yield put({
    type: actions.CHANGE_MALL_TYPE,
    mallType,
  });
}

export default function* () {
  yield all([
    takeEvery(actions.INIT_DATA_SAGA, initData),
    takeEvery(actions.UPDATE_DATA_SAGA, updateData),
    takeEvery(actions.UPDATE_DATA_TEMP_SAGA, updateDataTemp),
    takeEvery(actions.CLEAR_CART_SAGA, clearCart),
    takeEvery(actions.CHANGE_MALL_TYPE_SAGA, changeMallType),
  ]);
}
