import axios from "axios";
import { getAccessToken, getLoginData, sortObjectByKeys } from "./utility";
import loaderActions from "@iso/redux/loader/actions";
import authAction from "@iso/redux/auth/actions";
import { store } from "../../redux/store";

const { changeLoader } = loaderActions;
const { logout } = authAction;

axios.interceptors.request.use(
  async function (config) {
    if (!store.getState().Loader.condition && !config.dontLoad) store.dispatch(changeLoader(true));
    const accessToken = getAccessToken();
    const loginData = getLoginData();
    var CryptoJS = require("crypto-js");
    var sorter = "";
    let securityKey = "";
    if (config.data != undefined) {
      sorter = JSON.stringify(sortObjectByKeys(JSON.parse(config.data)));
      securityKey = CryptoJS.HmacSHA256(sorter, process.env.REACT_APP_HEADER_KEY).toString();
    }
    // const loginAxios = axios.create();
    // const res = await loginAxios.get("https://geolocation-db.com/json/");
    config.baseURL = process.env.REACT_APP_API_KEY_v2;
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      // "X-Forwarded-For": res.data.IPv4,
      JianCustom: securityKey,
      cLoginUser: loginData == undefined ? "PUBLIC" : loginData.memberID,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    if (!store.getState().Loader.condition);
    store.dispatch(changeLoader(true));
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (store.getState().Loader.condition);
    store.dispatch(changeLoader(false));
    return response;
  },
  async function (error) {
    if (!store.getState().Loader.condition);
    store.dispatch(changeLoader(false));

    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest?._retry && error?.response?.data != "INVALID TOKEN") {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      axios.defaults.headers.Authorization = "Bearer " + accessToken;
      return axios(originalRequest);
    } else if (error?.response?.data == "INVALID TOKEN") {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

function refreshAccessToken() {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY_v2 + "/Token/RefreshToken",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        Token: getAccessToken(),
      }),
    })
      .then((response) => {
        resolve(response.data.RESULT);
        localStorage.setItem("accessToken", response.data.RESULT);
      })
      .catch((error) => {
        // store.dispatch(logout());
      });
  });
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
