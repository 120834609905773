import React, { useState, useRef, useContext, useEffect, Fragment } from "react";
import LayoutContent from "@iso/components/utility/layoutContent";
import LayoutWrapper from "@iso/components/utility/layoutWrapper";
import moment from "moment";
import IntlMessages from "@iso/components/utility/intlMessages";
import { HTML5Backend } from "react-dnd-html5-backend";
import ImgCrop from "antd-img-crop";
import {
  Button,
  Upload,
  Col,
  Form,
  Tooltip,
  message,
  Row,
  DatePicker,
  Modal,
  Input,
  InputNumber,
  Select,
  Table,
  Checkbox,
  Divider,
  Radio,
  Tag,
} from "antd";
import { Map } from "immutable";
import Swal from "sweetalert2";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { PlusOutlined } from "@ant-design/icons";
import ApiCall from "./ApiCall";
// import * as XLSX from "xlsx";
import XLSX from "xlsx-js-style";
import FileSaver from "file-saver";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const type = "DragableUploadList";
const { Option } = Select;
const TextArea = Input;
var CryptoJS = require("crypto-js");

// Called when logout to clear all login credentials in local storage
export function clearToken() {
  // localStorage.removeItem("id_token");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("loginData");
}

// Get access token as bearer token to authenticate API calling
export function getAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
}

// Get Login Data stored in local storage during Sign In
export function getLoginData() {
  const data = localStorage.getItem("loginData");
  if (data) {
    const decrypt = aesDecrypt(data);
    const profileData = JSON.parse(decrypt);
    return profileData;
  } else return undefined;
}

export function getMemberProfile(MemberID) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/Member/GetMemberProfile/" + MemberID)
      .then((response) => {
        resolve(response.data.RESULT);
      })
      .catch((error) => {});
  });
}

export function getWalletBalance(MemberID, WalletType) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/Wallet/GetWalletBalance",
      JSON.stringify({
        MemberID: MemberID,
        WalletType: WalletType,
      })
    )
      .then((response) => {
        resolve(response.data.RESULT);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

// Get Token to authenticate to private dasboard routes
export function getToken() {
  try {
    const idToken = localStorage.getItem("accessToken");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

// Check Security Pin to authenticate wallet related functions, return a true or false
export const checkSecurityPin = (MemberID, Password) => {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/Auth/CheckSecurityPin",
      JSON.stringify({
        MemberID: MemberID,
        Password: Password,
      })
    )
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        message.error("Wrong Security Pin");
        resolve(false);
      });
  });
};

export const checkPassword = (MemberID, Password) => {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/Auth/CheckPassword",
      JSON.stringify({
        MemberID: MemberID,
        Password: Password,
      })
    )
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        message.error("Wrong Password");
        resolve(false);
      });
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Remove leading zero for mobile number
export function removeLeadingZero(number) {
  if (number[0] == 0) return number.substring(1);
  else return number;
}

export function dummyRequest({ file, onSuccess }) {
  setTimeout(async () => {
    onSuccess("ok");
  }, 0);
}

// Prompt a confirmation alert for user before making an action, return a true or false
export function confirmationAlert(title = "Are you sure?", icon = "warning") {
  return new Promise(async function (resolve, reject) {
    Swal.fire({
      title: title,
      //text: "All the information is correct",
      icon: icon,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#111473",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) resolve(true);
      else reject(false);
    });
  });
}

export function beforeUpload(file) {
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    message.error("File size must be smaller than 5 MB");
  }
  return file.size / 1024 / 1024 < 5 ? true : Upload.LIST_IGNORE;
}

// Return a thousand separator for all integer displays
export function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Used for ANTD Upload to make the upload list draggable
const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ""}`}
      style={{ cursor: "move" }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

// A upload list component when needed to upload a list of files
export const UploadImages = ({ productImageListURL, setProductImageListURL, ratio = { x: 278, y: 278 } }) => {
  const moveRow = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = productImageListURL[dragIndex];
      setProductImageListURL(
        update(productImageListURL, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [productImageListURL]
  );
  return (
    <Row gutter={[16, 16]} justify="center">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item name="productImageList">
          <DndProvider backend={HTML5Backend}>
            <ImgCrop aspect={ratio.x / ratio.y} quality={1}>
              <Upload
                beforeUpload={beforeUpload}
                maxCount={5}
                accept=".jpg, .jpeg, .png"
                listType="picture"
                customRequest={dummyRequest}
                onChange={(item) => {
                  setProductImageListURL(item.fileList);
                }}
                fileList={productImageListURL}
                itemRender={(originNode, file, currFileList) => (
                  <DragableUploadListItem
                    originNode={originNode}
                    file={file}
                    fileList={currFileList}
                    moveRow={moveRow}
                  />
                )}
              >
                <Button>Choose File</Button>
              </Upload>
            </ImgCrop>
          </DndProvider>
        </Form.Item>
      </Col>
    </Row>
  );
};

// A function that upload file to Alibaba OSS, it takes in the file and file path defined, then return the generated url
export const UploadDocs = (file, Pathway) => {
  return new Promise(async function (resolve, reject) {
    var dataString = "";
    await getBase64(file).then((data) => {
      const splitString = data.split(",");
      dataString = splitString[1];
    });
    var fileType = file.type.split("/");
    var filename = file.name.split(".");

    ApiCall.post(
      "/Utility/UploadFile",
      JSON.stringify({
        DataString: dataString,
        FileType: fileType[1],
        FileName: filename[0],
        Pathway: Pathway + fileType[1],
      })
    ).then(async (response) => {
      const imageList = {
        url: response.data.RESULT,
        imageCategory: "0",
        imageNo: 0,
        fileType: fileType[1],
      };
      resolve(imageList);
    });
  });
};

// Alert Messages

export function AlertSuccessMessage(title) {
  return new Promise(async function (resolve, reject) {
    Swal.fire({
      icon: "success",
      title: title,
    });
  });
}

export function AlertFailedMessage(title) {
  return new Promise(async function (resolve, reject) {
    Swal.fire({
      icon: "error",
      title: title,
    });
  });
}

// Validation Functions that returns a true or false

export function checkProductCode(productCode) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/General/CheckProductCode/" + productCode)
      .then((response) => {
        if (response.data.MESSAGE === "SUCCESS") resolve(true);
        else resolve(false);
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

export const CheckPostCode = (PostCode, StateID, CountryID) => {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/General/CheckPostCode",
      JSON.stringify({
        PostCode: PostCode,
        StateID: StateID,
        CountryID: CountryID,
      })
    )
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        message.error(error.response.data.ERROR);
        resolve(false);
      });
  });
};

export function checkEmail(email) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/General/CheckNewEmail/" + email, null, { dontLoad: true })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

export function CheckMobile(mobileCode, mobile) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/General/CheckNewMobile",
      JSON.stringify({
        MobileCode: mobileCode,
        Mobile: mobile,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.MESSAGE === "SUCCESS") resolve(true);
      })
      .catch((error) => resolve(false));
  });
}

export function CheckIdentity(IdentityType, IdentityValue) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/General/CheckNewIdentity",
      JSON.stringify({
        IdentityType: IdentityType,
        IdentityValue: IdentityValue,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.MESSAGE === "SUCCESS") resolve(true);
      })
      .catch((error) => resolve(false));
  });
}

export function CheckUsername(Username) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/General/CheckNewUsername/" + Username, null, { dontLoad: true })
      .then((response) => {
        if (response.data.MESSAGE === "SUCCESS") resolve(true);
        else resolve(false);
      })
      .catch((error) => resolve(false));
  });
}

export function CheckMemberID(MemberID) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/General/CheckMemberID/" + MemberID)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => resolve(false));
  });
}
export function CheckReferralID(ReferralID) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post("/General/CheckIfReferralMemberIsValid/" + ReferralID)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => resolve(false));
  });
}
export const checkStockBalance = (products, WarehouseID = "W0000001") => {
  return new Promise(function (resolve, reject) {
    var ProductBalance = [];
    products.map((product, index) => {
      ProductBalance.push({
        ProductCode: product.productCode,
        WarehouseID: WarehouseID,
        Quantity: product.quantity,
      });
    });

    ApiCall.post(
      "/Inventory/v1/CheckProductBalance",
      JSON.stringify({
        ProductBalance: ProductBalance,
      })
    )
      .then((response) => {
        var checkStock = true;
        response.data.RESULT.map((stock) => {
          if (stock.stockBalance - stock.quantity < 0) {
            checkStock = false;
          }
        });
        if (!checkStock) message.warning("Insufficient Stock");
        resolve(checkStock);
      })
      .catch(() => {
        message.error("Failed to Checkout");
      });
  });
};

// Format for ReactQuill Editor format

export const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

// Editable Table Modules, currently supported string, number, date, select, textarea

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  tableName,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType,
  selectList,
  ...restProps
}) => {
  const dateFormat = "YYYY-MM-DD";
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (inputType === "date") {
      form.setFieldsValue({
        [dataIndex]: moment(record[dataIndex], "YYYY-MM-DD"),
      });
    } else {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, tableName);
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editing && inputType === "string" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : editing && inputType === "number" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            //precision={2}
          />
        </Form.Item>
      ) : editing && inputType === "date" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <DatePicker ref={inputRef} onBlur={save} format={dateFormat} />
        </Form.Item>
      ) : editing && inputType === "select" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select ref={inputRef} onSelect={save}>
            {selectList.map((item) => (
              <Option value={item.parameterValue}>{item.parameterValue}</Option>
            ))}
          </Select>
        </Form.Item>
      ) : editing && inputType === "textArea" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <TextArea ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const colProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 8,
  className: "colstyle",
};

// Encryption functions

export function aesEncrypt(text) {
  var encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_HEADER_KEY);

  return encrypted.toString();
}

export function aesDecrypt(text) {
  var decrypted = CryptoJS.AES.decrypt(text, process.env.REACT_APP_HEADER_KEY);

  return decrypted.toString(CryptoJS.enc.Utf8);
}

// Used to sort Api call data for verification purposes
export function sortObjectByKeys(o) {
  return Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});
}

// Miscellaneous
// A member list popup component that takes in visibility variables, then return the selected member id
export const MemberListSelection = ({ isModalVisible, setIsModalVisible, memberIDChanged, setSelectedMemberID }) => {
  const intl = useIntl();
  const [refresh, setRefresh] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [FilterVariable, setFilterVariable] = useState({
    Fullname: "",
    MemberID: "",
  });

  useEffect(async () => {
    await ApiCall.post(
      "/Member/GetMemberSimpleList",
      JSON.stringify({
        Ranking: 0,
        Nationality: 0,
      })
    )
      .then((response) => {
        response.data.RESULT.map((data, index) => {
          data.key = data.memberID;
        });
        setMemberList(response.data.RESULT);
      })
      .catch((erorr) => {
        setMemberList([]);
      });
  }, []);

  useEffect(() => {
    var list = memberList;
    if (FilterVariable.Fullname != "" || FilterVariable.Fullname != null) {
      list = list.filter((item) => item.fullname.toLowerCase().includes(FilterVariable.Fullname.toLowerCase()));
    }

    if (FilterVariable.MemberID != "" || FilterVariable.MemberID != null) {
      list = list.filter((item) => item.memberID.toLowerCase().includes(FilterVariable.MemberID.toLowerCase()));
    }

    setFilteredData(list);
  }, [FilterVariable, memberList]);

  const columns = [
    {
      title: <IntlMessages id="sms.memberID" />,
      dataIndex: "memberID",
      key: "memberID",
      width: "15%",
    },
    {
      title: <IntlMessages id="sms.fullname" />,
      dataIndex: "fullname",
      key: "fullname",
      width: "20%",
    },
    {
      title: <IntlMessages id="sms.mobileNo" />,
      dataIndex: "mobile",
      key: "mobile",
      width: "15%",
      render: (value, row) => {
        return (
          <div>
            {row.mobileCode}
            {row.mobile}
          </div>
        );
      },
    },
    {
      title: <IntlMessages id="sms.nationality" />,
      dataIndex: "nationality",
      key: "nationality",
      width: "10%",
    },
    {
      title: <IntlMessages id="sms.ranking" />,
      dataIndex: "ranking",
      key: "ranking",
      width: "15%",
    },
  ];

  return (
    <Modal
      className="modalclass"
      // title={modalText.title}
      visible={isModalVisible}
      footer={null}
      onCancel={() => {
        setIsModalVisible(false);
      }}
      width={800}
      style={{ borderRadius: "10px" }}
    >
      <div style={{ padding: "25px" }}>
        <div style={{ marginBottom: "20px" }}>
          <Row gutter={[40, 16]} justify="space-between">
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="colstyle">
              <h2>
                <IntlMessages id="filter.memberID" />
              </h2>
              <Input
                placeholder={intl.formatMessage({ id: "filter.memberID" })}
                value={FilterVariable.MemberID}
                onChange={(e) => {
                  setFilterVariable({
                    ...FilterVariable,
                    MemberID: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="colstyle">
              <h2>
                <IntlMessages id="filter.memberName" />
              </h2>
              <Input
                placeholder={intl.formatMessage({ id: "filter.memberName" })}
                value={FilterVariable.Fullname}
                onChange={(e) => {
                  setFilterVariable({
                    ...FilterVariable,
                    Fullname: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="colstyle">
              <Row
                style={{
                  marginTop: "1%",
                  marginRight: "0%",
                  float: "right",
                }}
              >
                <Col>
                  <Button
                    style={{ width: 100 }}
                    type="default"
                    onClick={() => {
                      setFilterVariable({
                        Fullname: "",
                        MemberID: "",
                      });
                      setRefresh(!refresh);
                    }}
                  >
                    <IntlMessages id="filter.reset" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Table
          className="slim-table memberTable"
          columns={columns}
          dataSource={filteredData}
          rowKey="ID"
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                setIsModalVisible(false);
                setSelectedMemberID(record.memberID);
                memberIDChanged(record.memberID);
              },
            };
          }}
        />
      </div>
    </Modal>
  );
};

export const AddressBook = ({ selectedAddress, setSelectedAddress }) => {
  const [form] = Form.useForm();
  const loginData = getLoginData();
  const intl = useIntl();
  const [memberAddressBookList, setMemberAddressBookList] = useState([]);
  const [mobileCodeList, setMobileCodeList] = useState([]);
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateListShipment, setStateListShipment] = useState([]);
  const [focusAddressBook, setFocusAddressBook] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    ApiCall.get("/Common/MobileCode")
      .then(function (response) {
        setMobileCodeList(response.data.RESULT);
      })
      .catch(function (error) {
        console.log(error);
      });

    ApiCall.get("/Common/Country/" + "1")
      .then((response) => {
        const countryList = response.data.RESULT;
        const filteredCountry = countryList.filter((country) => country.parameterValue == 127);
        setCountryList(filteredCountry);
      })
      .catch(() => {
        message.error("Failed to get Country List!");
      });
  }, []);
  useEffect(() => {
    ApiCall.post(
      "/AddressBook/MemberAddressBookList",
      JSON.stringify({
        MemberID: loginData.memberID,
        AddressBookID: "",
        AddressBookName: "",
        AddressBookType: "",
        Statusx: "",
      })
    ).then((response) => {
      setSelectedAddress(response.data.RESULT.find((e) => e.addressBookType == "1") || response.data.RESULT[0]);
      setMemberAddressBookList(response.data.RESULT);
    });
  }, [refresh]);
  useDidMountEffect(() => {
    var country = {
      value: focusAddressBook.countryID,
    };
    getStateList(country);
    form.setFieldsValue({
      AddressBookName: focusAddressBook.addressBookName,
      AddressBookType: 0,
      FirstName: focusAddressBook.firstName,
      LastName: "",
      Email: focusAddressBook.email,
      MobileCode: focusAddressBook.mobileCode,
      MobileNo: focusAddressBook.mobileNo,
      Address1: focusAddressBook.address1,
      Address2: focusAddressBook.address2,
      Address3: focusAddressBook.address3,
      PostCode: focusAddressBook.postCode,
      AreaName: focusAddressBook.areaName,
      State: { label: focusAddressBook.stateName, value: focusAddressBook.stateID },
      Country: { label: focusAddressBook.countryName, value: focusAddressBook.countryID },
      DefaultAddress: focusAddressBook.addressBookType == "0" ? false : true,
    });
  }, [focusAddressBook]);

  const getStateList = (country) => {
    ApiCall.get("/Common/State/" + country.value).then((response) => {
      setStateListShipment(response.data.RESULT);
    });
  };

  const createAddress = (values) => {
    ApiCall.post(
      "/AddressBook/CreateAddressBook",
      JSON.stringify({
        MemberID: loginData.memberID,
        AddressBookName: values.AddressBookName,
        AddressBookType: 0,
        FirstName: values.FirstName,
        LastName: "",
        Email: values.Email,
        MobileCode: values.MobileCode,
        MobileNo: values.MobileNo,
        Address1: values.Address1,
        Address2: values.Address2,
        Address3: values.Address3,
        PostCode: values.PostCode,
        AreaID: values.AreaID,
        AreaName: values.AreaName,
        StateID: values.State.value,
        StateName: values.State.label,
        CountryID: values.Country.value,
        CountryName: values.Country.label,
        CreatedBy: loginData.memberID,
      })
    )
      .then((response) => {
        AlertSuccessMessage(
          intl.formatMessage({
            id: "alert.success.add",
          })
        );
        setIsModalVisible(false);
        setRefresh(!refresh);
      })
      .catch((error) => {
        message.error(
          intl.formatMessage({
            id: "alert.fail.add",
          })
        );
      });
  };

  const updateAddress = (values) => {
    console.log(values);
    ApiCall.post(
      "/AddressBook/UpdateAddressBook",
      JSON.stringify({
        MemberID: loginData.memberID,
        AddressBookID: focusAddressBook.addressBookID,
        AddressBookName: values.AddressBookName,
        AddressBookType: 0,
        FirstName: values.FirstName,
        LastName: "",
        Email: values.Email,
        MobileCode: values.MobileCode,
        MobileNo: values.MobileNo,
        Address1: values.Address1,
        Address2: values.Address2,
        Address3: values.Address3,
        PostCode: values.PostCode,
        AreaID: values.AreaID,
        AreaName: values.AreaName,
        StateID: values.State.value,
        StateName: values.State.label,
        CountryID: values.Country.value,
        CountryName: values.Country.label,
        Statusx: "A",
        UpdatedBy: loginData.memberID,
      })
    )
      .then((response) => {
        ApiCall.post(
          "/AddressBook/ChangeAddressBookType",
          JSON.stringify({
            MemberID: loginData.memberID,
            AddressBookID: focusAddressBook.addressBookID,
            AddressBookType: values.DefaultAddress ? "1" : "0",
            UpdatedBy: loginData.memberID,
          })
        );
        AlertSuccessMessage(
          intl.formatMessage({
            id: "alert.success.update",
          })
        );
        setRefresh(!refresh);
      })
      .catch((error) => {
        message.error(
          intl.formatMessage({
            id: "alert.fail.update",
          })
        );
      });
  };

  return (
    <>
      <Row style={{ border: "1px solid black", padding: "10px", borderRadius: "2px" }}>
        <Col span={12} style={{ alignSelf: "center" }}>
          {memberAddressBookList.length > 0 ? (
            <div>
              <div>
                <b>{selectedAddress?.firstName}</b> | (+{selectedAddress?.mobileCode}) {selectedAddress?.mobileNo}
              </div>
              <div>{selectedAddress?.address1}</div>
              <div>{selectedAddress?.address2}</div>
              <div>
                {selectedAddress?.areaName + ", " + selectedAddress?.stateName + ", " + selectedAddress?.countryName}
              </div>
            </div>
          ) : (
            <p style={{ fontWeight: "bold", color: "red" }}>Address is empty, please add an address</p>
          )}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              setIsModalVisible(true);
            }}
            type={"link"}
          >
            Change
          </Button>
        </Col>
      </Row>
      <Modal
        className="modalclass"
        // title={modalText.title}
        visible={isModalVisible}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Confirm
          </Button>
        }
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title={"My Address"}
        width={800}
        style={{ borderRadius: "10px" }}
      >
        {!addAddress && !editAddress ? (
          <div>
            {memberAddressBookList.map((e) => (
              <Fragment>
                <Row
                  onClick={() => {
                    setSelectedAddress(e);
                    // setIsModalVisible(false);
                  }}
                >
                  <Col span={2}>
                    <Radio checked={e?.addressBookID == selectedAddress?.addressBookID} />
                  </Col>
                  <Col span={20}>
                    <div>
                      <b>{e.firstName}</b> | (+{e.mobileCode}) {e.mobileNo}
                    </div>
                    <div>{e.address1}</div>
                    <div>{e.address2}</div>
                    <div>{e.areaName + ", " + e.stateName + ", " + e.countryName}</div>
                    <Row style={{ paddingTop: "5px" }}>
                      {e.addressBookType == "1" ? <Tag color="blue">Default</Tag> : <Tag color="black">Normal</Tag>}
                    </Row>
                  </Col>
                  <Col span={2}>
                    <Button
                      onClick={() => {
                        setFocusAddressBook(e);
                        setEditAddress(true);
                      }}
                      type={"link"}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
                <Divider />
              </Fragment>
            ))}
            <Row gutter={[40, 16]} justify="space-between">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} style={{ textAlign: "left" }}>
                <Button
                  style={{ marginLeft: "12px" }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setAddAddress(true);
                  }}
                >
                  Add New Address
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Form
              layout="vertical"
              form={form}
              scrollToFirstError
              onFinish={async (values) => {
                var proceed = true;
                if (values.Country.value == "127") {
                  await CheckPostCode(values.PostCode, values.State.value, values.Country.value).then((test) => {
                    if (!test) {
                      proceed = false;
                    }
                  });
                }
                if (proceed) {
                  if (addAddress) {
                    createAddress(values);
                  } else if (editAddress) {
                    updateAddress(values);
                  }
                }
              }}
            >
              <Form.Item
                name="AddressBookName"
                label={intl.formatMessage({
                  id: "checkout.addressBookName",
                })}
                colon={false}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                name="FirstName"
                label={intl.formatMessage({
                  id: "checkout.shippingName",
                })}
                colon={false}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({
                  id: "checkout.mobileNo",
                })}
              >
                <Row justify="start">
                  <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item name="MobileCode">
                      <Select style={{ width: "100%" }}>
                        {mobileCodeList
                          .filter(({ parameterValue }) => parameterValue == 60)
                          .map(({ parameterName, parameterValue }) => (
                            <Option key={parameterValue} value={parameterValue}>
                              {parameterName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Form.Item
                      name="MobileNo"
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]{8,11}$"),
                          message: intl.formatMessage({
                            id: "error.mobileFormat",
                          }),
                        },
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                name="Email"
                label={intl.formatMessage({
                  id: "checkout.shippingEmail",
                })}
                colon={false}
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Address1"
                label={intl.formatMessage({
                  id: "checkout.shippingAddressLine",
                })}
                rules={[{ required: true }]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                name="Address2"
                label={intl.formatMessage({
                  id: "checkout.shippingAddressLine2",
                })}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                name="Address3"
                label={intl.formatMessage({
                  id: "checkout.shippingAddressLine3",
                })}
              >
                <Input maxLength={50} />
              </Form.Item>

              <Form.Item
                name="PostCode"
                label={intl.formatMessage({
                  id: "checkout.shippingPostCode",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="AreaName"
                label={intl.formatMessage({
                  id: "checkout.AreaName",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                name="Country"
                label={intl.formatMessage({
                  id: "checkout.shippingCountry",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  labelInValue
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(e) => getStateList(e, "shipment")}
                >
                  {countryList.map(({ parameterName, parameterValue }) => (
                    <Option value={parameterValue}>{parameterName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="State"
                label={intl.formatMessage({
                  id: "checkout.shippingState",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select labelInValue style={{ width: "100%" }}>
                  {stateListShipment.map(({ parameterName, parameterValue }) => (
                    <Option value={parameterValue}>{parameterName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="DefaultAddress" valuePropName="checked">
                <Checkbox>Set As Default Address</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setAddAddress(false);
                    setEditAddress(false);
                    form.resetFields();
                  }}
                >
                  <IntlMessages id="registration.previous" />
                </Button>
                <Button className="isoOrderBtn" type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
                  {editAddress ? <IntlMessages id="checkout.update" /> : <IntlMessages id="checkout.add" />}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

// Can use it to prevent useEffect called upon load for the first time
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

// export to excel module
export const exportToExcel = (json, fileName, title = "Miko LifeStyle 2024") => {
  // run when have record
  if (json.length > 0) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    json.map((e) => {
      for (var key of Object.keys(e)) {
        e[key] = {
          v: e[key],
          t: "s",
          s: {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
              bottom: { style: "thin" },
            },
          },
        };
      }
    });

    var header = [];

    // Use to generate header name for excel
    for (var key of Object.keys(json[0])) {
      // Set first letter to upper case
      var string = key[0].toUpperCase() + key.slice(1);

      // Split header by capital letter if not string like ID and PV
      header.push(string.split(!string.match(/(?=[A-Z]{2,})/) ? /(?=[A-Z])/ : /(?=[A-Z]{2,})/).join(" "));
    }

    const ws = XLSX.utils.json_to_sheet(json, { origin: "A5" });

    if (!ws["!merges"]) ws["!merges"] = [];
    ws["!merges"].push({ s: { r: 0, c: 0 }, e: { r: 0, c: header.length - 1 } });
    ws["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: header.length - 1 } });
    ws["!merges"].push({ s: { r: 2, c: 0 }, e: { r: 2, c: header.length - 1 } });
    ws["!merges"].push({ s: { r: 3, c: 0 }, e: { r: 3, c: header.length - 1 } });
    XLSX.utils.sheet_add_aoa(
      ws,
      [[{ v: title, t: "s", s: { font: { name: "Buffalo", sz: 24 }, alignment: { horizontal: "center" } } }]],
      {
        origin: "A1",
      }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [[{ v: fileName, t: "s", s: { font: { name: "Buffalo", sz: 12 }, alignment: { horizontal: "center" } } }]],
      {
        origin: "A2",
      }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          {
            v: moment().format("DD MMMM YYYY HH:mm:ss A"),
            t: "s",
            s: { font: { name: "Buffalo", sz: 12 }, alignment: { horizontal: "center" } },
          },
        ],
      ],
      { origin: "A3" }
    );
    XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A4" });

    // Border style for all header
    var headerStyle = [];
    header.map((e) => {
      headerStyle.push({
        v: e,
        t: "s",
        s: {
          font: { bold: true },
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          },
        },
      });
    });

    XLSX.utils.sheet_add_aoa(ws, [headerStyle], { origin: "A5" });

    // Build the column width
    ws["!cols"] = Object.keys(json[0]).map((v) => {
      var maxx = json.reduce((w, r) => Math.max(w, r[v]?.length ?? 0), 10);
      return { wch: maxx + 2 };
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
};

// export to csv module
export const exportToCSV = (json, fileName, header) => {
  // var FileSaver = require("file-saver");
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";

  const ws = XLSX.utils.json_to_sheet(json);
  XLSX.utils.sheet_add_aoa(ws, [header]);

  const jsonKeys = header ? header : Object.keys(json[0]);

  let objectMaxLength = [];
  for (let i = 0; i < json.length; i++) {
    let value = json[i];
    for (let j = 0; j < jsonKeys.length; j++) {
      if (typeof value[jsonKeys[j]] == "number") {
        objectMaxLength[j] = 10;
      } else {
        const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

        objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
      }
    }

    let key = jsonKeys;
    for (let j = 0; j < key.length; j++) {
      objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length;
    }
  }

  const wscols = objectMaxLength.map((w) => {
    return { width: w };
  });

  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const test = () => {
  console.log(`\n\n--------------------==~==~==~==[ STARTING DEMO... ]==~==~==~==--------------------\n`);
  console.log("`XLSX.version` ......... = " + XLSX.version);
  console.log("`XLSX.style_version` ... = " + XLSX.style_version);

  // STEP 1: Create a new Workbook
  const wb = XLSX.utils.book_new();

  // STEP 2: Create data rows
  let row1 = ["a", "b", "c"];
  let row2 = [1, 2, 3];
  let row3 = [
    {
      v: "Courier: 24",
      t: "s",
      s: {
        font: { name: "Courier", sz: 24 },
        border: {
          top: { style: "medium" },
          left: { style: "medium" },
          right: { style: "medium" },
          bottom: { style: "medium" },
        },
      },
    },
    { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
    { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
    { v: "line\nbreak!", t: "s", s: { alignment: { wrapText: true } } },
  ];

  // STEP 3: Create Worksheet, add data, set cols widths
  const ws = XLSX.utils.aoa_to_sheet([row1, row2, row3]);
  ws["!cols"] = [{ width: 30 }, { width: 20 }, { width: 20 }];
  XLSX.utils.book_append_sheet(wb, ws, "browser-demo");

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, "xlsx-js-style-demo.xlsx");
};

export function reverseString(str) {
  return str.split("").reverse().join("");
}
