import clone from "clone";
import actions from "./actions";

const initState = {
  loadingInitData: false,
  view: "gridView",
  viewTopbarCart: false,
  productQuantity: [],
  products: {},
  tempProductQuantity: [],
  tempProducts: {},
  mallType: "3",
};
export default (state = initState, action) => {
  switch (action.type) {
    case actions.INIT_DATA:
      return {
        ...state,
        loadingInitData: true,
        productQuantity: action.payload.productQuantity,
        products: action.payload.products,
        mallType: action.payload.mallType,
      };
    case actions.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case actions.VIEW_TOPBAR_CART:
      return {
        ...state,
        viewTopbarCart: action.viewTopbarCart,
      };
    case actions.UPDATE_DATA:
      return {
        ...state,
        products: clone(action.products),
        productQuantity: clone(action.productQuantity),
      };
    case actions.UPDATE_DATA_TEMP:
      return {
        ...state,
        tempProducts: clone(action.tempProducts),
        tempProductQuantity: clone(action.tempProductQuantity),
      };
    case actions.CLEAR_CART:
      return {
        ...state,
        products: {},
        productQuantity: [],
      };
    case actions.CLEAR_CART_TEMP:
      return {
        ...state,
        tempProducts: {},
        tempProductQuantity: [],
      };
    case actions.CHANGE_MALL_TYPE:
      return {
        ...state,
        mallType: action.mallType,
      };
    default:
      return state;
  }
};
