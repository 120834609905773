import Enlang from "./entries/en-US";
import Zhlang from "./entries/zh-Hans-CN";
import Salang from "./entries/ar_SA";
import Itlang from "./entries/it_IT";
import Eslang from "./entries/es_ES";
import Frlang from "./entries/fr_FR";
import MsLang from "./entries/ms-MY";
import merge from "deepmerge";

const AppLocale = {
  en: Enlang,
  zh: merge.all([Enlang, Zhlang]),
  sa: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
  ms: merge.all([Enlang, MsLang]),
};

export default AppLocale;
