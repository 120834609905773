import styled from "styled-components";
import { palette } from "styled-theme";

const LayoutContentStyle = styled.div`
  width: 100%;
  padding: 35px;
  background-color: #ffffff;
  border: 1px solid ${palette("border", 0)};
  border-radius: 15px;
  height: 100%;
  opacity: 1;
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export default LayoutContentStyle;
