import msMessages from "../locales/ms_MY.json";

const MsLang = {
  messages: {
    ...msMessages,
  },
  antd: null,
  locale: "ms-my",
};
export default MsLang;
