import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
.text-right{
  text-align: right;
}
.core-border{
  border:1px solid #d7d7d7;
  padding:20px;
  height: 100%;
}
.multiline-ellipsis{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Show only 2 lines */
    overflow: hidden;
    min-height:47.13px
}
.content-multiline-ellipsis{
.ql-editor{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height:55.36px;
    min-height:55.36px

}
}
////////////////FOOTER CSS//////////////////
.footer-outside {
  background-color: #1E1E1E!important;
  color: #fff!important;
  padding: 0px 0!important;
}

.footer-outside .container {
  max-width: 1440px;
  margin: 0 auto;
  padding:44px;
}

.footer-outside h3 {
  color: #fff;
  margin-bottom: 15px;
}

.footer-outside p {
  margin: 0 0 10px;
  color: #ccc;
}
.footer-outside p a {
  color: #ccc;
}
.footer-outside p:hover {
  color: #fff;
}

.payment-section {
  margin-top: 20px;
}
////////////////FOOTER CSS/////////////////
.max-width-customer{
  max-width: 1440px;
  margin:auto
}
.max-width-customer-mall{
  max-width: 1440px;
  margin:auto
}
.custom-select-profile{
  .ant-select-selection-item{
    color:#a8a59d
  }
}
.new-cart-table-ui{
  width:auto!important;
  border: none !important;
  .isoItemImage{
    width: 70px!important;
    margin:0px !important;
    border:1px solid #e9e9e9;
    padding:0px !important
  }
  .isoItemName{
    padding-left: 15px!important;
    color:#707070;
    font-weight: bold!important;
    align-content:center
  }
  .isoItemQuantity{
    color:#707070;
    align-content:center
  }
  thead{
    min-height: auto!important;
    border-bottom:1px solid #e9e9e9!important;
    padding-bottom: 5px!important;
    font-weight: bold;
    color:#979797;
    margin-bottom:15px;
  }
  .new-cart-summary-total{
    font-weight: bold;
    padding: 12px 0px;
    margin: 10px 0px;
    border-top: 1px solid #e9e9e9 !important;
    border-bottom: 1px solid #e9e9e9 !important;
  }
}
.registration-package-UI{
.ant-radio-button-wrapper{
  margin:10px;
  line-height: auto;
  border:none;
  text-align: center;
  height: 100%;
  border:1px solid #777;
  padding:15px 30px
}
.ant-radio-button-wrapper-checked{
  border:2px solid #1890FF ;
  background-color: #0084ff12;
}
.ant-radio-button-wrapper:not(:first-child)::before{
  border: none;
  background-color: none !important;
  background:none;

}
}
.ant-form-item{
  margin-bottom: 18px;
}
.gt-button {
  margin:0px 10px;
  min-width:150px;
  margin-bottom:10px;
  margin-left:0px;
  border:1px solid #33335B;
  background:#FF8109;
  color:white
}
.ant-page-header {
  width: 100%; 
  padding: 0 0 30px 0!important;
}
.ant-page-header-heading-title{
  color:#33335b !important
}
.ant-page-header-heading-extra{
  margin:auto 0 !important;
  overflow:auto
}
.firstbreadcrumb{
  color: #000066;
    font-weight: 500;
}
.payment-button-mobile{
  display:flex;
  justify-content:center;
}


///////////////swal form///////////////////////////////
button.swal2-styled { padding: 0.4em 1em;min-width: 100px;}

.sa-wrapper{ border:1px solid #c0c0c0; border-radius:4px; width:100%; max-width:400px; margin:auto;  margin-top:15px; display:flex;}
.sa-password-input{ border-radius:4px; border:none; padding:7px; width:90%; padding-right:10px;}
.sa-password-input:focus-visible{ border:none !important; outline:none;}
.sa-button-input { background: rgba(110, 125, 136, 0.15); border: none; font-size: 0.8em; color: #222; min-width: 65px; padding: 4px; margin: 8px;}

@media only screen and (max-width: 768px) {
  .isoCartTable{
    padding:0px 15px;
    overflow-x:unset!important;

  }
  .payment-button-mobile{
    display:block;
    text-align:center;
  }
  .ant-input{
    width:100% !important
  }
  .ant-picker{
    width:auto !important
  }
  .ant-select{
    width:100% !important
  }
}
.ant-select-dropdown{
  width:auto !important
}
@media only screen and (max-width: 768px) {
  .desktop-only{
    display:none;
  }
  .hide-font{
    display:none !important
  }
}
.ant-table-container{
  overflow:auto;
}

.ant-card-hoverable:hover{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px!important;
  border-color:none !important
}
  .ant-btn{
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  body{
    overflow-y:auto
  }
  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    // margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Poppins';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }
  .custom-quantity-input{
    .ant-input[disabled]{
      color:black;
      font-weight:bold
    }
    background:white;
    border-radius:6px;
    border:none;
    padding:0;
    color:black
  }
  .ant-modal-wrap {
    .modalclass {
      .ant-modal-content {
        background-color: #fff;
        border-radius: 0px;
        p{
          // color: white;
          margin-top: 50px;
        }
      svg{
        color: black;
      }
        .ant-modal-footer{
          border-top: 0px;
        }
        // .ant-modal-header{
        //   background-color: #4C5292;
        //   border-bottom: 0px;
        //   border-radius: 10px;
        //   .ant-modal-title{
        //     color: white;
        //     margin-top: 30px;
        //     font-size: 25px;
        //   }
        // }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

.memberTable {
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #bdb5b5!important;
  }
}


font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${"" /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  background: ${palette("secondary", 1)};
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header{
    padding:20px !important
  }
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}


.frame {
  width: 100%
}
@media only screen and (max-width: 767px) {
  .frame{
    width:auto
  }
}

.banner-padding{
  padding:25px 44px;
}
.aboutus-padding{
  padding:25px 44px;
}
.padding-responsive{
  padding:25px;
}

.padding-responsive-out{
  padding:20px 18px;
  margin:auto;
  max-width:90%;
}
@media (max-width: 767px) {
  .content-mobile{
    padding-bottom:25px;
  }
  .padding-responsive-out{
    max-width:100%;
  }
  .FormCss{
    padding:15px !important;
   
  }
  .banner-padding{
    padding:0px;
  }
  .aboutus-padding{
    padding:140px 40px 0px 40px
  }
  .padding-responsive{
    padding:15px;
  }
  
  .ant-spin-nested-loading > div > .ant-spin {
  position:fixed!important;
  top:0!important;
  left:0!important;
  z-index:4!important;
  display:block!important;
  width:100%!important;
  height:100%!important;
  max-height:100vh!important;    
  background: #ffffff91;
  z-index: 9999!important;
}
}

.time-css{
  color:#0073b7;
  border-radius:10px;
  padding:0.25em 1em;
  background-color:#E6EEF5;
  min-width: 130px;
}

.time-css-outside{
  color:#fff;
  border-radius:10px;
  padding:0.25em 1em;
  background-color:none;
  min-width: 130px;
  border:1px solid white
}
.announcement-dashboard{
  border:1px solid #80808033;
  padding:15px !important;
  border-radius:15px;
  background:white;
  .ant-tabs-content-holder{
    overflow:auto;
    height:327px;
    padding-right:20px;
  }
}
.events-image{
  width:100%;
  max-width: 100px;
  border-radius:10px
}
.events-desc{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .ql-editor {
    overflow: hidden;
   text-overflow: ellipsis;
    display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: 0; padding-right: 0;
  max-height: 52px;
  color: #777;
  }
}
.events-read{
  margin:auto;
  text-align:center;
  font-weight:500;
  color:#fff;
  background:black;
  border-radius:6px;
  padding:6px !important;
  font-size:0.8em
}
.events-title{
  font-weight:bold;
  color:#364D79;
}
.events-date-title{
  font-weight:500;
}
.events-date{
font-weight:500;
color: #DEAB5A;
}


/*-----------------------------------------------*/
  // style for table list
/*-----------------------------------------------*/
.ant-table {
  border:1px solid #e9e9e9
}
.tbTable {
  .ant-table-tbody > tr > td{
    padding:10px !important
  }
  .ant-select-selector{
    background-color: ${palette("primary", 0)};
    color: white;
    border-radius: 4px!important;  
  }
  width: 100%;
  .ant-select-arrow {
    color: white !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #eaeaea;
  }
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #eaeaea;
}

/*-----------------------------------------------*/
  // style for Profile list
/*-----------------------------------------------*/
.ant-upload-list-picture-card-container {
  // width: 100%; height: auto
}


/*-----------------------------------------------*/
  // style for Card
/*-----------------------------------------------*/

.card-title{
  font-size:1.1em;
  font-weight:500;
  color:#d39024

}
.ant-select-single.ant-select-open .ant-select-selection-item{
  color:white
}
.text-height{
  color:#555;
  font-size:14px;
  letter-spacing:.3px;
  text-transform:uppercase;
  font-weight:500;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
height:44px;
}
.text-height-outside{
  color:#555;
  font-size:13px;
  letter-spacing:.2px;
  text-transform:uppercase;
  font-weight:500;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.product-desc{
  color:#999;
  font-weight:normal;
  font-size:13px;
  padding-top:6px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
line-height:normal
}
.product-desc-outside{
  color:#999;
  font-weight:normal;
  font-size:13px;
  padding-top:6px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
line-height:normal
}
.search-input-outside {
      .ant-input {
        background: none;
      }
      width: 100%;
      border: none;
      border-radius: 20px;
      min-height: 40px;
      border: 1px solid #8d8d8d57;
      background: none;
      .ant-input-prefix {
        padding-right: 5px;
        color: #8d8d8d;
        font-size: 16px;
      }
    }
.announcement-card{
  .ql-editor{
    padding:0px
  }
  .ant-card-body{
    padding:20px 0px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom:3px dashed #E3E3E3
  }
}
// .ant-select-selection-placeholder{
//   color:white
// }
.ant-table-thead > tr > th{
  padding:10px !Important
}
.table-date{
  width:100px
}
.slim-table {
  .ant-table-tbody > tr > td {
    // height: 5px;
    padding: 7px;
  }
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #FF810933 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #FF810933;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #FF810963;
    border-radius: 10px;
    border: 3px none #ffffff;
  }
  .light-mode {
    color-scheme: light;
    background-color: white;
    color: black;
  }
  .dark-mode {
    /* Don't forget the color-scheme css attribute. This will avoid light scrollbars in dark mode. */
    color-scheme: dark;
    background-color: black;
    color: white;
  }
`;

export default GlobalStyles;
