import React from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import Maintenancef from "./containers/Pages/404/404Outside";
import AppProvider from "./AppProvider";
import ApiCall from "./library/helpers/ApiCall";
import { useState } from "react";
import { useEffect } from "react";

const App = () => {
  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    ApiCall.get("/Setting/IsMaintenance", null, { dontLoad: true }).then((response) => {
      setMaintenance(response.data.RESULT == "1" ? true : false);
    });
  }, []);

  // useEffect(() => {
  //   setInterval(() => {
  //     if (!maintenance)
  //       ApiCall.get("/Setting/IsMaintenance", null, { dontLoad: true }).then((response) => {
  //         setMaintenance(response.data.RESULT == "1" ? true : false);
  //       });
  //   }, 10000);
  // }, []);

  return (
    <>
      {maintenance ? (
        <Maintenancef />
      ) : (
        <Provider store={store}>
          <AppProvider>
            <>
              <GlobalStyles />
              <Routes />
            </>
          </AppProvider>
        </Provider>
      )}
    </>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
