import { isServer } from "@iso/lib/helpers/isServer";

const productDatas = [];
function getInitData() {
  let productQuantity = [];
  const products = {};
  const value = JSON.parse(localStorage.getItem("mallType"));
  const mallType = value != undefined ? value : "3";
  if (!isServer) {
    const cartProductQuantity = localStorage.getItem("cartProductQuantity");
    let cartProducts = localStorage.getItem("cartProducts");
    if (cartProducts && cartProductQuantity) {
      cartProducts = JSON.parse(cartProducts);
      JSON.parse(cartProductQuantity).forEach((product) => {
        const productCode = product.productCode;
        if (!isNaN(product.quantity)) {
          productQuantity.push({
            productCode,
            quantity: parseInt(product.quantity, 10),
          });
          products[productCode] = {
            ...cartProducts[productCode],
            price: parseFloat(cartProducts[productCode].memberPrice, 10),
          };
        }
      });
    } else {
      productDatas.forEach((product) => {
        productQuantity.push({
          productCode: product.productCode,
          quantity: 1,
        });
        products[product.productCode] = product;
      });
    }
  }
  return { productQuantity, products, mallType };
}

export default getInitData();
